export enum KycVerificationStatus {
  RestrictedSoon = -2,
  Rejected = -1,
  InComplete = 0,
  Verified = 1,
  Pending = 2,
}

export enum PaymentProvider {
  Stripe = 'stripe',
  Rapyd = 'rapyd',
  '2c2p' = '2c2p',
  Paymongo = 'paymongo',
}

export type PaymentKycStatus = {
  id: string;
  paymentAccountId: string;
  paymentProvider: PaymentProvider;
  verificationStatus: KycVerificationStatus;
  companyFullname: string;
  companyUENNumber: string;
  bankName: string;
  bankRoutingNumber: string;
  bankAccountNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};
export interface PaymentCredential {
  paymentProvider: PaymentProvider;
  publicKey: string;
  secretKey: string;
}

export type InitKycData = {
  addressCity: string;
  addressLine1: string;
  addressLine2: string;
  addressPostalCode: string;
  bankAccountNumber: string;
  bankName: string;
  bankRoutingNumber: string;
  birthday: string;
  businessProfileId: string;
  companyFullname: string;
  companyUENNumber: string;
  companyWebsite?: string;
  companyVerificationDocBack?: string;
  companyVerificationDocFront?: string;
  country: string;
  created: string;
  currency: string;
  email: string;
  firstName: string;
  id: string;
  idNumber: string;
  lastName: string;
  phoneNumber: string;
  verificationDocBack?: string;
  verificationDocFront?: string;
  verificationStatus: KycVerificationStatus;
  paymentProvider: PaymentProvider;
};
